﻿import googleMapStyles from "./features/google-map-styles";
import setUpAccordion from "./features/accordion";
import setUpCardsSlider from "./features/cards-slider";
import setUpFileUpload from "./features/file-upload";
import setUpFloatField from "./features/float-field";
import setUpGoogleMap from "./features/google-map";
import setUpGoogleMapRegion from "./features/google-map-region";
import setUpHeroCarousel from "./features/hero-carousel";
import setUpMakeTableScrollable from "./features/make-table-scrollable";
import setUpNewsFilter from "./features/news-filter";
import setUpScrollableTable from "./features/scrollable-table";
//import setUpShareButton from "./features/share-button";
//import setUpShareModal from "./features/share-modal";
import setUpTextCopier from "./features/text-copier";
//import setUpVideoModal from "./features/video-modal";

(function () {
    // Scrollable tables
    document.querySelectorAll('.js-make-table-scrollable').forEach(tableEl => setUpMakeTableScrollable(tableEl));
    document.querySelectorAll('.js-scrollable-table').forEach(rootEl => setUpScrollableTable(rootEl));

    // Social sharing options
    //const shareModalEl = document.querySelector('.js-share-modal');
    //const shareButtonEls = document.querySelectorAll('.js-share-button');
    //setUpShareModal(shareModalEl, shareButtonEls);
    //shareButtonEls.forEach(rootEl => setUpShareButton(rootEl, shareModalEl));

    // Videos
    //const videoModalEl = document.querySelector('.js-video-modal');
    //setUpVideoModal(videoModalEl);

    // Google Maps
    window.initMaps = () => {
        console.log('Google Maps API loaded.');
        document.querySelectorAll('.js-google-map').forEach(rootEl => setUpGoogleMap(rootEl, googleMapStyles));
    };
    if (window.google && window.google.maps) {
        window.initMaps();
    }

    // Other
    document.querySelectorAll('.js-accordion').forEach(rootEl => setUpAccordion(rootEl));
    document.querySelectorAll('.js-cards-slider').forEach(rootEl => setUpCardsSlider(rootEl));
    document.querySelectorAll('.js-file-upload').forEach(rootEl => setUpFileUpload(rootEl));
    document.querySelectorAll('.js-float-field').forEach(rootEl => setUpFloatField(rootEl));
    document.querySelectorAll('.regionMap').forEach(rootEl => setUpGoogleMapRegion(rootEl));
    document.querySelectorAll('.js-news-filter').forEach(rootEl => setUpNewsFilter(rootEl));
    document.querySelectorAll('.js-hero-carousel').forEach(rootEl => setUpHeroCarousel(rootEl));
    document.querySelectorAll('.js-text-copier').forEach(rootEl => setUpTextCopier(rootEl));
})();