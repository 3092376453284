/**
 * Text Copier
 * @description : Copy to clipboard functionality for link sharing
 */

export default function setUpTextCopier(rootEl) {

    const valueEl = rootEl.querySelector('.js-text-copier__value');
    const buttonEl = rootEl.querySelector('.js-text-copier__button');
    const messageEl = rootEl.querySelector('.js-text-copier__message');
    const messageTextEl = rootEl.querySelector('.js-text-copier__message-text');

    buttonEl.addEventListener('click', function () {
        valueEl.select();
        if (document.execCommand('copy')) {
            messageTextEl.textContent = 'Copied';
            messageEl.removeAttribute('hidden');
            setTimeout(function () {
                messageEl.setAttribute('hidden', true);
            }, 2000);
        }
    });

};