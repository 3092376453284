/**
 * Scrollable Table
 * @description : Visual cues for tables that scroll horizontally
 */

export default function setUpScrollableTable(rootEl) {

    const scrollBoxEl = rootEl.querySelector('.js-scrollable-table__scroll-box');

    const tolerancePx = 4;

    let leftHintIsShown = false;
    let rightHintIsShown = false;

    function updateScrollHints() {
        if (scrollBoxEl.scrollWidth > scrollBoxEl.offsetWidth) {
            const isScrolledRight = scrollBoxEl.scrollLeft > 0 + tolerancePx;
            const isScrolledLeft = scrollBoxEl.scrollLeft < (scrollBoxEl.scrollWidth - scrollBoxEl.offsetWidth - tolerancePx);

            if (!leftHintIsShown && isScrolledRight) {
                rootEl.toggleAttribute('data-show-left-scroll-hint', true);
                leftHintIsShown = true;
            } else if (leftHintIsShown && !isScrolledRight) {
                rootEl.removeAttribute('data-show-left-scroll-hint', '');
                leftHintIsShown = false;
            }

            if (!rightHintIsShown && isScrolledLeft) {
                rootEl.toggleAttribute('data-show-right-scroll-hint', true);
                rightHintIsShown = true;
            } else if (rightHintIsShown && !isScrolledLeft) {
                rootEl.removeAttribute('data-show-right-scroll-hint', '');
                rightHintIsShown = false;
            }
        } else {
            if (leftHintIsShown) {
                rootEl.removeAttribute('data-show-left-scroll-hint', '');
                leftHintIsShown = false;
            }
            if (rightHintIsShown) {
                rootEl.removeAttribute('data-show-right-scroll-hint', '');
                rightHintIsShown = false;
            }
        }
    }

    scrollBoxEl.addEventListener('scroll', updateScrollHints);
    window.addEventListener('resize', updateScrollHints);
    updateScrollHints();

};