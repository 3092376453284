/**
 * Float Field
 * @description : Represents a form field made up of a floating label and control.
 */

export default function setUpFloatField(rootEl) {

    const valueEl = rootEl.querySelector('.js-float-field__value');

    const updateHasValueState = () => {
        rootEl.classList.toggle('has-value', Boolean(valueEl.value));
    }

    updateHasValueState();
    setTimeout(updateHasValueState, 200);

    ['input', 'change'].forEach(eventName => {
        valueEl.addEventListener(eventName, updateHasValueState);
    });

    window.addEventListener('load', () => {
        updateHasValueState();
        setTimeout(updateHasValueState, 200);
    });

    document.addEventListener('dblclick', updateHasValueState);

};
