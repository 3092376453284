/**
 * Google Map
 * @description : 
 */

export default function setUpGoogleMapRegion(rootEl) {
    const locations = JSON.parse(rootEl.dataset.locations)
    console.log(locations)

    function initializeMap() {
        // Create a new Google Map
        var map = new google.maps.Map(rootEl, {
            center: { lat: 0, lng: 0 }, // Set initial map center
            zoom: 10 // Set initial zoom level
        });

        // Iterate over the locationArray and add markers for each location
        locations.forEach(function (location) {
            // Parse the latitude and longitude values from the location string
            var latLng = location.split(',');

            // Create a marker for each location
            var marker = new google.maps.Marker({
                position: { lat: parseFloat(latLng[0]), lng: parseFloat(latLng[1]) },
                map: map
            });
        });
    }
    window.addEventListener('load', initializeMap());

    //var map;

    //async function initMap() {
    //    var center = { lat: @latlon[0], lng: @latlon[1]
    //};

    //map = new google.maps.Map(rootEl, {
    //    center: center,
    //    zoom: 17,
    //    disableDefaultUI: true,
    //    gestureHandling: 'cooperative',
    //    zoomControl: true,
    //    mapId: 'c09953ec30a0ea6d',
    //});

    //const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    //const customMarker = document.createElement("div");

    //customMarker.className = "map-marker";
    //customMarker.innerHTML = `
    //        <div class="c-map-marker">
    //            <div class="c-map-marker__inner">
    //                <img class="c-map-marker__logo" src="/images/map-marker.png">
    //                @if (locationName != null) {
    //                    <div class="c-map-marker__title">@locationName</div>
    //                }
    //            </div>
    //            <div class="c-map-marker__point"></div>
    //        </div>
    //    `

    //var marker = new AdvancedMarkerElement({
    //    map: map,
    //    position: center,
    //    content: customMarker,
    //});

};