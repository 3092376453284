/**
 * File Upload
 * @description : Copy to clipboard functionality for link sharing
 */

export default function setUpFileUpload(rootEl) {

    const inputEl = rootEl.querySelector('.js-file-upload__input');
    let existingFileName = inputEl.getAttribute('data-existing-file-name');
    const textEl = rootEl.querySelector('.js-file-upload__text');
    const removeEl = rootEl.querySelector('.js-file-upload__remove');

    const updateText = () => {
        textEl.textContent = existingFileName || '';

        if (inputEl.files.length === 1) {
            textEl.textContent = inputEl.files[0].name;
        } else if (inputEl.files.length > 1) {
            textEl.textContent = inputEl.files.length + ' files selected';
        }
    };

    inputEl.addEventListener('change', updateText);

    removeEl.addEventListener('click', function () {
        inputEl.value = existingFileName = '';
        updateText();
    });

    updateText();

};