/**
 * Hero Carousel
 * @description : 
 */

import Swiper, { Navigation, Pagination } from "swiper";

export default function setUpHeroCarousel(rootEl) {

    const swiperEl = rootEl.querySelector('.js-hero-carousel__swiper');
    const slideEls = rootEl.querySelectorAll('.js-hero-carousel__slide');

    const prevEl = rootEl.querySelector('.js-hero-carousel__prev');
    const nextEl = rootEl.querySelector('.js-hero-carousel__next');

    const paginationEl = rootEl.querySelector('.js-hero-carousel__pagination');

    if (slideEls.length < 2) {
        // We only have one slide, so don't create a Swiper instance.
        return;
    }

    const swiper = new Swiper(swiperEl, {
        loop: true,
        modules: [Navigation, Pagination],
        navigation: {
            prevEl,
            nextEl
        },
        pagination: {
            bulletActiveClass: 'is-active',
            bulletClass: 'c-carousel-pagination__item',
            bulletElement: 'li',
            clickable: true,
            el: paginationEl
        },
        speed: 800
    });

};