/**
 * Accordion
 * @description : List of expandable/collapsible sections
 */

export default function setUpAccordion(rootEl) {

    const itemEls = rootEl.querySelectorAll('.js-accordion__item');

    itemEls.forEach((itemEl) => setUpAccordionItem(itemEl));

};

function setUpAccordionItem(accordionItemEl) {

    const toggleEl = accordionItemEl.querySelector('.js-accordion__toggle');

    toggleEl.addEventListener('click', () => {
        accordionItemEl.classList.toggle('is-open');
    });

};
