/**
 * News Filter
 * @description : Filters news/stories/tips
 */

export default function setUpNewsFilter(rootEl) {
    const itemEls = rootEl.querySelectorAll('.js-filter-option');
    const newsitems = document.querySelectorAll('.js-news-item');
    const allOption = document.querySelector('.js-filter-all');
    const clearBtn = document.querySelector('.js-filter-clear');
    let filter = []

    itemEls.forEach((itemEl) => setUpFilterOption(itemEl));

    allOption.addEventListener('click', () => {
        itemEls.forEach((itemEl) => clearActiveOptions(itemEl));
        allActive()
    })

    clearBtn.addEventListener('click', () => {
        itemEls.forEach((itemEl) => clearActiveOptions(itemEl));
    })


    function setUpFilterOption(filterOption) {

        filterOption.addEventListener('click', () => {
            if (filterOption.classList.contains('c-news__filter-option--active')) {
                filterOption.classList.remove('c-news__filter-option--active');
            }
            else {
                filterOption.classList.add('c-news__filter-option--active');
                allOption.classList.remove('c-news__filter-option--active')
            }
            checkActiveFilters()
        });
    };

    function clearActiveOptions(filterOption) {
        filterOption.classList.remove('c-news__filter-option--active');
        allActive()
    };

    function allActive() {
        allOption.classList.add('c-news__filter-option--active')
        filter = []
        filterNews()
    }

    function checkActiveFilters() {
        let activeFilter = false;

        filter = []
        itemEls.forEach((itemEl) => {
            if (itemEl.classList.contains('c-news__filter-option--active')) {
                filter.push(itemEl.innerHTML);
                activeFilter = true
            }
        })

        if (activeFilter == false) {
            allActive()
        }

        filterNews()
    };

    function filterNews() {
        if (filter.length < 1) {
            newsitems.forEach((newsItem) => {
                newsItem.classList.remove('c-news__item--hidden')
            })
        } else {
            newsitems.forEach((newsItem) => {
                var regions = newsItem.getAttribute('data-regions').split(" , ").filter(x => x != "");

                if (regions.some(r => filter.includes(r))) {
                    newsItem.classList.remove('c-news__item--hidden')
                }
                else {
                    newsItem.classList.add('c-news__item--hidden')
                }
            })
        }
    }

};
