/**
 * Hero Carousel
 * @description : 
 */

import Swiper, { Scrollbar } from "swiper";

export default function setUpCardsSlider(rootEl) {

    const swiperEl = rootEl.querySelector('.js-cards-slider__swiper');

    const scrollbarEl = rootEl.querySelector('.js-cards-slider__scrollbar');

    const swiper = new Swiper(swiperEl, {
        breakpoints: {
            [window.breakpoints['sm']]: {
                slidesPerView: 1,
                spaceBetween: 24,
            },
            [window.breakpoints['md']]: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            [window.breakpoints['lg']]: {
                slidesPerView: 3,
                spaceBetween: 40
            },
            [window.breakpoints['xl']]: {
                slidesPerView: 3,
                spaceBetween: 48
            }
        },
        modules: [Scrollbar],
        scrollbar: {
            el: scrollbarEl,
            draggable: true,
            dragClass: 'js-cards-slider__scrollbar-drag'
        },
        slidesPerView: 1,
        spaceBetween: 20,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        watchSlidesVisibility: true
    });

};