/**
 * Make Table Scrollable
 * @description : 
 */

export default function setUpMakeTableScrollable(tableEl) {

    const scrollableTableEl = document.createElement('div');
    scrollableTableEl.className = 'rt-scrollable-table  c-scrollable-table  js-scrollable-table';

    const scrollBoxEl = document.createElement('div');
    scrollBoxEl.className = 'c-scrollable-table__scroll-box  js-scrollable-table__scroll-box';

    scrollableTableEl.appendChild(scrollBoxEl);

    tableEl.classList.toggle('c-scrollable-table__table', true);

    tableEl.parentElement.replaceChild(scrollableTableEl, tableEl);

    scrollBoxEl.appendChild(tableEl);

};
