/**
 * Google Map
 * @description : 
 */

export default function setUpGoogleMap(rootEl, mapStyles) {

    if (!window.google || !window.google.maps) {
        console.warn('Google Maps library was not found.')
    }

    const dataScriptEl = rootEl.querySelector('.js-google-map__data');
    const infoWindowTemplateEl = rootEl.querySelector('.js-google-map__info-window-template');
    const embedEl = rootEl.querySelector('.js-google-map__embed');

    const infoWindowTemplate = infoWindowTemplateEl ? infoWindowTemplateEl.textContent.trim() : '{pinTitle}';

    const data = JSON.parse(dataScriptEl.textContent);
    if (!data || !data.locations) {
        console.warn('Google map had no locations configured.');
        return;
    }

    const zoomLevel = data.zoomLevel || 15;
    const isSingleLocation = data.locations.length === 1;
    const isDesktop = window.matchMedia(`(min-width: ${window.breakpoints['desktop-design-bp']}px)`).matches;

    const googleMapsOptions = {
        mapTypeControl: isDesktop,
        styles: mapStyles || [],
        zoom: zoomLevel
    };

    if (isSingleLocation) {
        const singleLocation = data.locations[0];
        googleMapsOptions.center = {
            lat: parseFloat(singleLocation.latitude),
            lng: parseFloat(singleLocation.longitude)
        };
    }

    const map = new google.maps.Map(embedEl, googleMapsOptions);

    const icon = {
        url: '/dist/images/vector/graphics/map-marker.svg',
        scaledSize: isDesktop ? new google.maps.Size(59, 75) : new google.maps.Size(40, 50)
    };

    const bounds = new google.maps.LatLngBounds();

    data.locations.forEach(location => {
        const marker = new google.maps.Marker({
            position: { lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) },
            map,
            title: location.pinTitle,
            icon: icon
        });

        bounds.extend(marker.position);

        if (location.pinTitle) {
            let infoWindowContent = infoWindowTemplate;
            for (var propertyName in location) {
                infoWindowContent = infoWindowContent.replaceAll(`{${propertyName}}`, location[propertyName] || '');
            }

            const infoWindow = new google.maps.InfoWindow({
                content: infoWindowContent,
            });

            marker.addListener("click", () => {
                infoWindow.open({
                    anchor: marker,
                    map,
                    pixelOffset: 4,
                    shouldFocus: true,
                });
            });
        }

    });

    if (!isSingleLocation) {
        const idleListener = google.maps.event.addListener(map, 'idle', () => {
            if (map.getZoom() > zoomLevel) {
                map.setZoom(zoomLevel);
            }
            google.maps.event.removeListener(idleListener);
        });

        map.fitBounds(bounds);
    }

};